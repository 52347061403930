import {ResetCSS} from 'common/assets/css/style';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {theme} from 'common/theme/appCreative2';
import Seo from 'components/seo';
import {ContentWrapper, GlobalStyle} from 'containers/AppCreative2/appCreative2.style';
// import Banner from 'containers/AppCreative2/Banner';
import Navbar from 'containers/AppCreative2/Navbar';
import React, {Fragment} from 'react';
import Sticky from 'react-stickynode';
import {ThemeProvider} from 'styled-components';

import {Modal} from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import Footer from 'containers/WebApp/Footer';
// import {TextWrapper} from "../containers/AppCreative/ChooseUs/chooseUs.style";
import Text from "../common/components/Text";
import PrivacyPolicy from 'containers/CryptoModern/Privacy';

const AppCreativeTwo = () => {
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Seo title="Fortune Mine Games | Privacy Policy"/>
                <Modal/>
                <ResetCSS/>
                <GlobalStyle/>
                <Text content={""}/>
                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <PrivacyPolicy/>
                    <Footer/>
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};
export default AppCreativeTwo;
